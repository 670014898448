import { MotifComponent } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { Anchor } from '../../Anchor';
import {
  searchResultItemDescriptionCss,
  searchResultItemLinkCss,
  searchResultItemPageTitleCss,
} from './SearchResultItem.styled';
import type { SearchResultItemProps } from './SearchResultItem.types';

export const replaceEmTags = (inputString: string): (string | JSX.Element)[] => {
  const regex = /<em>(.*?)<\/em>/gi;
  const parts = inputString.split(regex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // Odd index means it's between <em> and </em>, so replace content
      return <strong key={index}>{part}</strong>;
    }

    // Even index means it's outside <em> tags, so render as is
    return part;
  });
};

export const SearchResultItem: FC<SearchResultItemProps> = ({ description, pageTitle, slug }) => {
  return (
    <article className={MotifComponent.SEARCH}>
      <h6 className={searchResultItemPageTitleCss}>
        <Anchor href={slug} className={searchResultItemLinkCss}>
          {pageTitle}
        </Anchor>
      </h6>
      {description && (
        <p className={searchResultItemDescriptionCss}>{replaceEmTags(description)}</p>
      )}
    </article>
  );
};

SearchResultItem.displayName = 'SearchResultItem';
