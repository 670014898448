import { css } from '@emotion/css';

import { Gray, maxWidth, mobileMediaQuery, Spacing } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m, MotifComponent } from '../../motif';
import { globalHeaderHeight } from './GlobalHeader.constants';

/**
 * Inserts margin onto children.
 *
 * Outer children start and end with {@link outerMargin} and inbetween an {@link innerMargin} is
 * inserted.
 *
 * TODO: Rewrite using `grid-gap` when we start using grid.
 */
const childrenMarginCss = (outerMargin: number, innerMargin: number) => css`
  & > * {
    margin-left: ${innerMargin / 2}px;
    margin-right: ${innerMargin / 2}px;
  }

  & > *:first-child {
    margin-left: ${outerMargin}px;
    margin-right: ${innerMargin / 2}px;

    *[dir='rtl'] & {
      margin-right: ${outerMargin}px;
      margin-left: ${innerMargin / 2}px;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & > *:last-child {
    margin-left: ${innerMargin / 2}px;
    margin-right: ${outerMargin}px;

    *[dir='rtl'] & {
      margin-right: ${innerMargin / 2}px;
      margin-left: ${outerMargin}px;
    }
  }
`;

const globalHeaderCss = css`
  height: ${globalHeaderHeight}px;
  max-height: ${globalHeaderHeight}px;
  display: flex;
  align-items: center;
  /* TODO: this doesnt really show well, and needs to work with subnav/banners. */
  /* box-shadow: ${m('--box-shadow-m')}; */

  color: ${m('--global-header-fg-color')};
  background-color: ${m('--global-header-bg-color')};

  /* Prevent the header from expanding the body to fit its contents. */
  max-width: 100vw;
  overflow-x: clip;
  overflow-y: revert;

  /* Stretch the header to the sides and ensure it's visible. */
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.NAVIGATOR};

  opacity: 1;
  &.hidden {
    opacity: 0;
    transform: translateY(-100%);
  }
  transition: opacity 250ms ease-in, transform 250ms ease-out;

  ${childrenMarginCss(20, Spacing.MD)}
`;

/**
 * Style for the desktop header. Assumes there are 4 elements: [logo, menu, nav, cta].
 *
 * The nav is the only one that expands.
 */
export const globalHeaderDesktopCss = css`
  /* Prevent the header from expanding the body to fit its contents. */
  max-width: 100vw;
  overflow-x: clip;

  /* Stretch the header to the sides and ensure it's visible. */
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.NAVIGATOR};

  opacity: 1;
  &.hidden {
    opacity: 0;
    transform: translateY(-100%);
  }
  transition: opacity 250ms ease-in, transform 250ms ease-out;
`;

/**
 * Override user defined justification for ultrawide screens. This is to ensure that the nav menu is
 * not rendered far away from the parent nav item.
 *
 * Handles edge case:
 *
 * - User is on a a ultra-wide device
 * - Site uses Nav Menu
 * - User hovers over a Nav Item that renders a menu
 * - User should be able to move their mouse to the menu without the menu closing due to it being a
 *   long distance from the parent element.
 */
export const globalHeaderDesktopNavItemsUltraWideAlignCss = css`
  && {
    @media (min-width: ${maxWidth}px) {
      justify-content: center;
    }
  }
`;

/** Double selector so the style is not overridden by `globalHeaderMobileCss` */
export const globalHeaderDesktopNavItemsStartAlignCss = css`
  && {
    justify-content: flex-start;
  }
`;

/** Double selector so the style is not overridden by `globalHeaderMobileCss` */
export const globalHeaderDesktopNavItemsCenterAlignCss = css`
  && {
    justify-content: center;
  }
`;

/** Double selector so the style is not overridden by `globalHeaderMobileCss` */
export const globalHeaderDesktopNavItemsEndAlignCss = css`
  && {
    justify-content: flex-end;
  }
`;

/** Style for the mobile header. Assumes there are 3 elements: [menu, logo, cta] */
export const globalHeaderMobileCss = css`
  ${globalHeaderCss}
  & > *:first-child,
  & > *:last-child {
    display: flex;
    flex-grow: 1;
    flex-basis: 100px;
  }

  & > *:first-child {
    justify-content: flex-start;
  }

  & > *:last-child {
    justify-content: flex-end;
  }
`;

// NOTE: This uses triple specific selection to override the double specific
// button styles like `.css-ymmsc2.button-secondary:hover`.
export const globalHeaderDesktopNavButtonCss = css`
  > svg {
    fill: ${m('--global-header-item-color')};
    transition: all 0.15s ease-out;
  }

  &&& {
    background: transparent;
    border: 2px solid ${m('--global-header-item-color')};
    padding: 0 7px; /* Must override the padding on button. */
  }

  &&&:hover {
    border-color: ${m('--global-header-item-hover-color')};

    /* Clearing out styles from the regular button. */
    box-shadow: none;
    transform: none;

    > svg {
      fill: ${m('--global-header-item-hover-color')};
    }
  }
`;

export const globalHeaderMobileNavButtonCss = css`
  background-color: transparent;
  border: none;
  color: ${m('--foreground-color')};
  padding: 4px 8px;
  cursor: pointer;
`;

export const globalHeaderCtaDesktopCss = css`
  display: flex;
  align-items: center;

  ${childrenMarginCss(0, Spacing.MD)}
`;

/** Styles for all buttons in Global Header */
export const globalHeaderButtonCss = css`
  box-shadow: none;
  /* Hack to hide icons in CTAs. */
  i[class^='icon-'],
  img,
  svg {
    display: none;
  }
`;

/** In mobile layout we want to display flat button style with smaller font. This enables that. */
export const globalHeaderCtaMobileCss = css`
  & > *:not(:first-child) {
    margin-left: 16px;
    *[dir='rtl'] & {
      margin-left: unset;
      margin-right: 16px;
    }
  }

  /* NOTE: Triple specific selector to override button's double-specific styles. */
  & .${MotifComponent.BUTTON}.${MotifComponent.BUTTON}.${MotifComponent.BUTTON} {
    ${globalHeaderButtonCss}
    font-size: smaller;
    margin-right: 0;
    padding: 4px;
    width: inherit; /* buttons on mobile render full width by default which we don't want here */
    *[dir='rtl'] & {
      margin-right: unset;
      margin-left: 0;
    }
  }
`;

export const globalHeaderNavButtonCss = css`
  width: 40px;
  height: 40px;
  border: 1px solid ${Gray.V250};
  border-radius: 50%;
  background-color: transparent;
`;
// We moved the styles into the div inside the header to fix the
// Safari 16.2 dropdown bug where the dropdowns where hidden
export const globalHeaderDesktopContainerCss = css`
  ${globalHeaderCss}

  padding: 0 40px;

  /**
   * We are overriding the overflow from globalHeaderCss because of a bug
   * on Safari 16.2. Not sure why this works so this is a bandaid. Ideally we don't do this
   */
  overflow: visible;

  & > nav {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  span {
    font-weight: ${m('--action-desktop-font-weight')};
    font-size: ${m('--action-desktop-font-size')};
    margin: 0 ${m('--spacing-xs')};

    ${mobileMediaQuery} {
      font-weight: ${m('--action-mobile-font-weight')};
      font-size: ${m('--action-mobile-font-size')};
    }
  }
`;

export const globalHeaderMobileBarCss = css`
  margin: -7.5px -5px;
`;
