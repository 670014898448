import { css } from '@emotion/css';

import { BackgroundColor, Black, Blue, Gray, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const autoCompleteContainerCss = (open: boolean, setMaxWidth?: boolean): string => css`
  align-self: center;
  background: ${BackgroundColor.White};
  border-radius: ${m('--border-radius-l')};
  border-bottom: ${!open ? undefined : '0px'};
  border-bottom-left-radius: ${!open ? `${m('--border-radius-l')}` : '0px'};
  border-bottom-right-radius: ${!open ? `${m('--border-radius-l')}` : '0px'};
  border: 1px solid ${Gray.V150};
  box-shadow: ${m('--box-shadow-s')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 52px;
  position: relative;
  width: 100%;
  z-index: 20;
  max-width: ${setMaxWidth ? '400px' : undefined};
  margin-inline-start: ${setMaxWidth ? 'auto' : undefined};

  :has(input:focus) {
    border: 1px solid ${Black.V150};
  }
`;

export const inputCss = css`
  border-style: none;
  box-sizing: border-box;
  color: ${Black.V150};
  font-family: 'Graphik', Helvetica, sans-serif;
  font-size: ${m('--p2-mobile-font-size')};
  font-weight: ${m('--p2-mobile-font-weight')};
  height: 25px;
  margin: ${m('--spacing-s')} 0;
  outline: none;
  padding: 0;
  vertical-align: middle;
  width: 100%;
  *[dir='rtl'] & {
    text-align: right;
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--p2-desktop-font-size')};
    font-weight: ${m('--p2-desktop-font-weight')};
  }
`;

const resultsContainerCss = (noResults: boolean) =>
  css`
    top: 41px;
    /** Because container is absolute positioned when adding a border 1px it was 1px inside of the parent container below fixed */
    left: -1px;
    width: calc(100% + 2px);
    position: absolute;
    list-style: none;
    margin-top: ${m('--spacing-xs')};
    max-height: 175px;
    overflow-y: auto;
    padding-left: 0;
    background: ${BackgroundColor.White};
    z-index: 20;
    border: 1px solid ${Black.V150};
    box-sizing: border-box;
    border-top: 0px;
    border-bottom: ${noResults ? undefined : '0px'};
    border-bottom-right-radius: ${!noResults ? undefined : `${m('--spacing-s')};`};
    border-bottom-left-radius: ${!noResults ? undefined : `${m('--spacing-s')};`};
    webkit-scrollbar {
      width: 7px;
    }
    webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-right: 2px ${BackgroundColor.White} solid;
      background-color: ${Gray.V100};
      border-radius: ${m('--border-radius-s')};
    }
    :before {
      content: '';
      background: ${Gray.V100};
      position: absolute;
      top: 0;
      left: ${m('--spacing-m')};
      height: 1px;
      width: calc(100% - ${m('--spacing-xl')};);
    }
  `;

export const resultsContainerWithResultsCss = resultsContainerCss(true);
export const resultsContainerNoResultsCss = resultsContainerCss(false);

export const autocompleteListItemCss = css`
  cursor: pointer;
  padding: ${m('--spacing-s')} ${m('--spacing-xl')};
  margin: 0 ${m('--spacing-m')} 0 ${m('--spacing-m')};
  font-size: 18px;
  color: ${Gray.V300};
  line-height: 20px;
  border-radius: 6px;
  :hover {
    background: ${Gray.V100};
    color: ${Black.V150};
  }
`;

export const searchIconCss = css`
  margin-block: ${m('--spacing-m')} ${m('--spacing-s')};
  margin-inline: ${m('--spacing-m')} ${m('--spacing-s')};
  min-width: 20px;
`;

export const cancelButtonCss = css`
  background: transparent;
  border: none;
  color: ${Blue.V200};
  cursor: pointer;
  font-family: 'Graphik', Helvetica, sans-serif;
  font-size: ${m('--action-mobile-font-size')};
  font-weight: ${m('--action-mobile-font-weight')};
  line-height: ${m('--action-mobile-font-line-height')};
  margin-block: auto;
  margin-inline: ${m('--spacing-s')} ${m('--spacing-m')};
  padding-inline: 0;
  white-space: nowrap;

  :hover,
  :focus {
    color: ${Black.V150};
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--action-desktop-font-size')};
    font-weight: ${m('--action-desktop-font-weight')};
    line-height: ${m('--action-desktop-font-line-height')};
    margin-inline: ${m('--spacing-m')} ${m('--spacing-l')};
  }
`;

export const searchButtonCss = css`
  background: transparent;
  border: 1px solid ${m('--foreground-color')};
  :hover {
    background: transparent;
  }

  /* Overrides for the icon button style */
  box-shadow: none;

  && {
    --icon-size: 16px;
    --icon-color: ${m('--foreground-color')};
  }

  /* Overrides for the icon button hover styles */
  @media (hover: hover) {
    &:hover {
      --icon-color: ${m('--global-header-bg-color')};
      background: ${m('--foreground-color')};
      border: 1px solid ${m('--foreground-color')};
      transform: none;
    }
  }
`;

export const rowCss = css`
  display: flex;

  svg {
    path {
      fill: ${Gray.V300};
    }
  }
`;

export const seeResultsCss = css`
  padding: ${m('--spacing-s')} ${m('--spacing-xxl')} ${m('--spacing-l')};
  font-size: ${m('--text-mobile-font-size')};
  line-height: 20px;
  color: ${Blue.V200};
  background: white;
  cursor: pointer;
  /** Because container is absolute positioned when adding a border 1px it was 1px inside of the parent container below fixed */
  left: -1px;
  width: calc(100% + 2px);
  position: absolute;
  top: 224px;
  border: 1px solid ${Black.V150};
  border-top: 0px;
  border-bottom-right-radius: ${m('--spacing-s')};
  border-bottom-left-radius: ${m('--spacing-s')};
  text-align: start;
  z-index: 20;

  ${nonMobileMediaQuery} {
    font-size: ${m('--text-desktop-font-size')};
  }
`;
