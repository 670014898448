import type { WidthType } from '@snapchat/snap-design-system-marketing';
import { Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { Form } from '../../../../components/Form/Form';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { renderRichTextMultiLineWithMarkings } from '../../../../utils/renderText/renderRichText';
import type { ArArkoseProtectedFormBlockQuery } from './arArkoseProtectedFormBlockQuery';
import { query } from './arArkoseProtectedFormBlockQuery';

type ArArkoseProtectedFormProps = {
  id: string;
};

export const ArArkoseProtectedFormBlock: FC<ArArkoseProtectedFormProps> = ({ id }) => {
  const { data } = useContentfulQuery<ArArkoseProtectedFormBlockQuery, ContentfulIdVariable>(
    query,
    {
      variables: { id },
    }
  );

  if (!data?.arArkoseProtectedFormBlock) {
    return null;
  }

  const {
    arkosePublicDevKey,
    arkosePublicProdKey,
    protectedForm,
    anchorId,
    maxColumns,
    subtitle,
    eyebrow,
    titleAlignment,
    title,
    titleAlignmentMobile,
    widthStyle,
    backgroundColor,
    isNarrow,
  } = data?.arArkoseProtectedFormBlock ?? {};

  return (
    <BlockSDS
      anchorId={anchorId}
      maxColumns={maxColumns ?? undefined}
      subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
      eyebrow={eyebrow}
      title={renderRichTextMultiLineWithMarkings(title)}
      titleAlignment={titleAlignment}
      titleAlignmentMobile={titleAlignmentMobile}
      widthStyle={(widthStyle as WidthType) ?? (isNarrow ? 'narrow' : undefined)}
      backgroundColor={backgroundColor}
    >
      <Form
        {...protectedForm}
        enableArkose={true}
        arkosePublicDevKey={arkosePublicDevKey}
        arkosePublicProdKey={arkosePublicProdKey}
      />
    </BlockSDS>
  );
};
