import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { injectGlobal } from '@emotion/css';
import type { AsyncDataController } from '@snapchat/async-data';
import { AsyncDataContext } from '@snapchat/async-data-browser';
import type { BrowserInfo } from '@snapchat/client-hints';
import { useBrowserLayoutEffect } from '@snapchat/mw-common/client';
import type { GlobalComponentsProps } from '@snapchat/mw-global-components';
import { GlobalComponentsContextProvider } from '@snapchat/mw-global-components';
import type { MapOf } from '@snapchat/snap-design-system-marketing';
import {
  BrowserFeaturesContext,
  defaultPrimitivesContext,
  GlobalHeaderContext,
  PrimitivesContext,
} from '@snapchat/snap-design-system-marketing';
import type { History } from 'history';
import type { FC, PropsWithChildren } from 'react';
import { StrictMode, useEffect, useRef, useState } from 'react';
import type { HelmetData } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';
import type { StaticRouterContext } from 'react-router';
import { BrowserRouter, StaticRouter, useHistory } from 'react-router-dom';

import type { AppProviderProps, PassThroughAppProviderProps } from './AppContext';
import { AppContext, defaultContext } from './AppContext';
import { Anchor } from './components/Anchor';
import { GlobalAnimations } from './components/Animations/GlobalAnimations';
import { Cart } from './components/Cart';
import type { ShopifyResponse } from './components/Cart/types';
import type { ConsumerContextProps } from './components/ConsumerContextProvider';
import {
  ConsumerContext,
  filtersToUrlParams,
  urlParamsToFilters,
} from './components/ConsumerContextProvider';
import {
  ContentfulLivePreview,
  ContentfulLivePreviewContextProvider,
} from './components/ContentfulLivePreview';
import { CookiePopup } from './components/CookiePopup';
import { DirectionDirective } from './components/DirectionDirective/DirectionDirective';
import { directionFromLocale } from './components/DirectionDirective/languageDirection';
import { Favicon } from './components/Favicon';
import { Footer } from './components/FooterWrapper';
import { HardcodedComponents } from './components/HardcodedComponents/HardcodedComponents';
import { Header } from './components/Header';
import { IntlProvider } from './components/IntlProvider';
import { LanguageDirective } from './components/LanguageDirective/LanguageDirective';
import { LoadingBar } from './components/LoadingBar';
import { LogAdBlockUsage } from './components/logging/LogAdBlockUsage';
import { LogCspViolation } from './components/logging/LogCspViolation';
import { LogPageLoad } from './components/logging/LogPageLoad';
import { LogScrollDepth } from './components/logging/LogScrollDepth';
import { MotifRootWrapper } from './components/MotifRootWrapper/MotifRootWrapper';
import { ShopifyProvider } from './components/ShopifyProvider';
import { Viewport } from './components/Viewport';
import { Config } from './config';
import type { PageLayoutContextProps } from './context/PageLayoutContext';
import { PageLayoutProvider } from './context/PageLayoutProvider';
import { SitewideConfigurationProvider } from './context/SitewideConfiguration/SitewideConfigurationProvider';
import { ErrorBoundary } from './ErrorBoundary';
import { FontChecker } from './helpers/FontChecker';
import { createIsCurrentUrl, isLocalUrl } from './helpers/getLocalPath';
import type { LoggingContext } from './helpers/logging';
import {
  logError,
  logEvent as internalLogEvent,
  logger,
  SubscribedEventType,
} from './helpers/logging';
import { googleAnalyticsEventName } from './helpers/logging/google/GoogleEventListener';
import { getCookieSettingsUrl } from './helpers/trackingCookies';
import { SingleCallbackCacheContext } from './hooks/useSingleCallback';
import { Routes } from './Routes';
import { getSiteMotif } from './styles/getSiteMotif';
import { ContentfulContext, getContentfulContext } from './utils/contentful/ContentfulContext';
import { initWebVitalsLogging } from './utils/initWebVitalsLogging';
import type { SingleCallbackCache } from './utils/singleCallback';
import { getTracer } from './utils/tracing/tracer';
import { userAgentHintsFromBowser } from './utils/userAgent/userAgentHints';

/**
 * App component props.
 *
 * This includes anything that can be used by sub-components in the AppProviderProps, and the others
 * are listed here.
 */
export interface AppProps extends PassThroughAppProviderProps {
  routerContext: StaticRouterContext;
  helmetContext: HelmetData;
  shopifyData?: ShopifyResponse;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  globalApolloClient?: ApolloClient<NormalizedCacheObject>;
  onHistory?: (history: History<unknown>) => void;
  browserFeatures: BrowserInfo;
  asyncDataController: AsyncDataController;
  pageLayoutContext: PageLayoutContextProps;
  globalPrivacyControl: boolean;
  singleCallbackCache: SingleCallbackCache;
}

export const HistoryCaptor: FC<{ onHistory: AppProps['onHistory'] }> = ({ onHistory }) => {
  const history = useHistory();
  onHistory?.(history);
  return null;
};

/** Entry point for the react application. */
export const App: FC<AppProps> = ({
  getCurrentUrl,
  currentLocale,
  supportedLocales,
  routerContext,
  userLocation,
  shopifyData,
  helmetContext,
  pageLayoutContext,
  apolloClient,
  globalApolloClient,
  siteData,
  browserFeatures,
  onRedirect,
  onHistory,
  asyncDataController,
  globalPrivacyControl,
  cookieManager,
  getUserInfo,
  createUuidV4,
  singleCallbackCache,
}) => {
  const appRenderTrace = getTracer().startSpan('appRender');

  const url = new URL(getCurrentUrl());

  // TODO: Make the Router a parameter in AppProps and just pass the relevant
  // routers as props.
  let Router: FC<PropsWithChildren>;

  if (Config.isClient) {
    // eslint-disable-next-line react/display-name
    Router = ({ children }) => (
      <BrowserRouter>
        <HistoryCaptor onHistory={onHistory} />
        {children}
      </BrowserRouter>
    );
  } else {
    // eslint-disable-next-line react/display-name
    Router = ({ children }) => (
      <StaticRouter location={url.pathname} context={routerContext}>
        {children}
      </StaticRouter>
    );
  }
  Router.displayName = 'Router';

  // A naive attempt to seed some high entropy client hints.
  // OK to run on re-render as the cache is hit.
  // Components that use high entropy hints are still encouraged to call the
  // async method to get the final value, but the default value should be
  // populated from cache (i.e. browserFeatures.getCachedHighEntropyHints) which
  // this will seed.
  //
  // NOTE: We don't include 'viewportWidth' and 'viewportHeight' here because
  // We don't include the necessary headers in the CDN cache key since they
  // have too high cardinality.
  void browserFeatures.getHighEntropyHintsAsync({
    hints: ['connection', 'reduceMotion'],
  });

  const userAgentHints = userAgentHintsFromBowser(browserFeatures);

  const isRtl = directionFromLocale(currentLocale) === 'rtl';

  // Sets the global logging context. Only works for client side.
  // See loggingInitServer for the server - side version.
  // Has to be run before any children render
  useBrowserLayoutEffect(() => {
    const appContextProvider = (): Partial<LoggingContext> => {
      // We want to get a new URL each time. This is so we can pick up
      // any changes pushed directly onto the URL, like via `history.pushState`.
      // This is a little controversial because anything added through `history.pushState`
      // DOES NOT get reflected by react.
      const url = new URL(getCurrentUrl());
      return {
        locale: currentLocale,
        url,
        hostname: url.hostname,
        userAgentHints,
        uaBrand: userAgentHints?.brand,
        uaPlatform: userAgentHints?.platform,
        path: url.pathname,
        userCountry: userLocation.country,
        isRtl,
      };
    };
    logger.addContextProvider(appContextProvider);

    return () => logger.removeContextProvider(appContextProvider);
  }, [currentLocale, url, isRtl, userAgentHints, userLocation.country]);

  // Initializes web vitals logging
  useEffect(() => {
    initWebVitalsLogging();
  }, [
    currentLocale,
    getCurrentUrl,
    isRtl,
    url.hostname,
    url.pathname,
    userAgentHints,
    userLocation.country,
  ]);

  const consumerContext: ConsumerContextProps = {
    supportedLocales,
    currentLocale: supportedLocales[currentLocale ?? defaultContext.currentLocale]!.code,
    logEvent: ({ type, label, url, component }) => {
      if (!(type && (label || url))) {
        return;
      }

      internalLogEvent({
        // By default we assume that SDS-M only logs user events, not any internal events.
        subscribedEventType: SubscribedEventType.USER_INTERACTION,
        // For legacy reasons, we default the component as CTA.
        // TODO: Clean up this assumption and make the event category mandatory.
        eventCategory: component ?? 'CTA',
        eventAction: type,
        eventLabel: label ?? (url ? `to: ${url}` : undefined),
      });
    },
    isUrlCurrent: createIsCurrentUrl(getCurrentUrl),
    getUrlParams: () => {
      const search = new URL(getCurrentUrl()).search;
      return urlParamsToFilters(search);
    },
    setUrlParams: (paramsObj: MapOf<string | undefined>) => {
      if (!Config.isClient) return;

      const { pathname } = window.location;
      const paramsStr = filtersToUrlParams(paramsObj);
      const newPath = `${pathname}?${paramsStr}`;

      // push to window to bypass scrolling behavior of redirectTo()
      window.history.pushState(null, '', newPath);
    },
  };

  // Test for duplicate SDSM contexts.
  // TODO: Write a proper invariant file. Tracking: https://jira.sc-corp.net/browse/ENTWEB-4284
  if (GlobalHeaderContext.displayName !== 'GlobalHeaderContextSDSM') {
    throw new Error(
      'Duplicate SDS-M packages found. See https://jira.sc-corp.net/browse/ENTWEB-4284'
    );
  }

  const globalComponentsContext: GlobalComponentsProps = {
    onError: error => {
      logError({
        component: 'GlobalComponents',
        error: error instanceof Error ? error : undefined,
        message: typeof error === 'string' ? error : undefined,
      });
    },
    Anchor,
    currentLocale,
    isPreview: Config.isPreview,
    isSSR: Config.isSSR,
    hostname: Config.domainName,
    onEvent: ({ component, action, label, url }) => {
      internalLogEvent({
        subscribedEventType: SubscribedEventType.USER_INTERACTION,
        event: googleAnalyticsEventName,
        eventCategory: component ?? 'GlobalComponent',
        eventAction: action,
        eventLabel: label ?? (url ? `to: ${url}` : 'generic'),
      });

      // If we're logging a Navigation event (has a URL), and we're leaving the
      // tab, we should flush the loggers so that we attempt to send the logs
      // out before the browser terminates this tab's JS loop.
      if (url && !isLocalUrl(new URL(url, getCurrentUrl()))) {
        void logger.flush();
      }
    },
    supportedLocales,
    // Moved here from part of footer refactor
    onLocaleChange: (locale: string) => {
      const url = new URL(window.location.href);
      url.searchParams.set('lang', locale);
      window.location.replace(url);
    },
    isUrlCurrent: createIsCurrentUrl(getCurrentUrl),
    globalApolloClient,
  };

  const [siteDataState, setSiteData] = useState<Record<string, unknown> | undefined>(siteData);
  const headerPortalRef = useRef<HTMLElement>(null);
  const pageBottomStickyPortalRef = useRef<HTMLElement>(null);

  const appContext: AppProviderProps = {
    currentLocale,
    supportedLocales,
    userLocation,
    getCurrentUrl,
    isRTL: isRtl,
    siteData: siteDataState,
    setSiteData,
    headerPortalRef,
    pageBottomStickyPortalRef,
    onRedirect,
    cookieManager,
    getUserInfo,
    createUuidV4,
  };

  const contentfulContext = getContentfulContext(currentLocale, url.searchParams);

  const cookieSettingsUrl = getCookieSettingsUrl(
    Config.domainName,
    Config.trackingSettings.cookieDomain
  );

  const motif = getSiteMotif();

  const primitivesContext = { ...defaultPrimitivesContext, Anchor };

  // if site config defines global styles, inject them.
  Config.globalStyles && injectGlobal(Config.globalStyles);

  const renderedApp = (
    <StrictMode>
      <ErrorBoundary tracer={getTracer()}>
        <Router>
          <ContentfulContext.Provider value={contentfulContext}>
            <ContentfulLivePreviewContextProvider>
              <ApolloProvider client={apolloClient}>
                <AsyncDataContext.Provider value={{ controller: asyncDataController }}>
                  <ConsumerContext.Provider value={consumerContext}>
                    <PrimitivesContext.Provider value={primitivesContext}>
                      <GlobalComponentsContextProvider value={globalComponentsContext}>
                        <BrowserFeaturesContext.Provider value={browserFeatures}>
                          <HelmetProvider context={helmetContext}>
                            <IntlProvider>
                              <AppContext.Provider value={appContext}>
                                <SingleCallbackCacheContext.Provider value={singleCallbackCache}>
                                  <LogCspViolation />
                                  <LogPageLoad />
                                  <LogAdBlockUsage />
                                  <LogScrollDepth />
                                  <GlobalAnimations />
                                  <DirectionDirective />
                                  <LanguageDirective />
                                  <ContentfulLivePreview />
                                  <Favicon />
                                  <Viewport />
                                  <FontChecker />
                                  <SitewideConfigurationProvider>
                                    {/* this needs feature flags */}
                                    <HardcodedComponents />
                                    <PageLayoutProvider sessionValue={pageLayoutContext}>
                                      <ShopifyProvider shopifyData={shopifyData}>
                                        <MotifRootWrapper motif={motif} tag="section">
                                          <Header config={Config} />
                                          <LoadingBar />
                                          <Routes />
                                          <Footer cookieSettingsUrl={cookieSettingsUrl} />
                                          <CookiePopup
                                            backgroundColor={
                                              Config.theme?.defaultPageBackgroundColor
                                            }
                                            isClient={Config.isClient}
                                            globalPrivacyControl={globalPrivacyControl}
                                          />
                                          {Config.shopify && <Cart />}
                                        </MotifRootWrapper>
                                      </ShopifyProvider>
                                    </PageLayoutProvider>
                                  </SitewideConfigurationProvider>
                                </SingleCallbackCacheContext.Provider>
                              </AppContext.Provider>
                            </IntlProvider>
                          </HelmetProvider>
                        </BrowserFeaturesContext.Provider>
                      </GlobalComponentsContextProvider>
                    </PrimitivesContext.Provider>
                  </ConsumerContext.Provider>
                </AsyncDataContext.Provider>
              </ApolloProvider>
            </ContentfulLivePreviewContextProvider>
          </ContentfulContext.Provider>
        </Router>
      </ErrorBoundary>
    </StrictMode>
  );

  appRenderTrace.endSpan();
  return renderedApp;
};
