import { gql } from '@apollo/client';
import type {
  Analytics,
  Criteria,
  NavigatorCallsToActionItem,
} from '@snapchat/mw-contentful-schema';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../Analytics';
import type { AnalyticsDataProps } from '../Analytics/types';
import { ctaFragment } from '../CallToAction';
import { criteriaFragment } from '../Experiment';
import type { ImageDataProps } from '../Image';
import { fragments as mediaFragments } from '../Media';
import { navigationCtaFragment } from '../NavigationCta';

// =================================================================================================
// Navigator Item Fragment
// =================================================================================================

export const navigatorItemFragment = gql`
  fragment NavigatorItemFieldsOnly on NavigatorItem {
    ...ContentfulSysId
    title {
      json
    }
    url
    analytics {
      ...AnalyticsAll
    }
    hideCriteria {
      ...CriteriaAll
    }
  }
  ${analyticsFragments.all}
  ${contentfulSysIdFragment}
  ${criteriaFragment}
`;

export interface NavigatorItemData extends ContentfulTypedSysProps<'navigatorItem'> {
  title: RichText;
  url: string;
  analytics: Analytics;
  hideCriteria?: Criteria;
}

// =================================================================================================
// Navigator Fragment
// =================================================================================================

export const navigatorFragment = gql`
  fragment NavigatorLocal on Navigator {
    ...ContentfulSysId
    title
    logoV2 {
      ... on Image {
        ...ImageAll
      }
    }
    theme
    backgroundColor
    navigatorItemsCollection(limit: 15) {
      items {
        ...NavigatorItemFieldsOnly
        # Note: We're not using any sort of recursive definition to avoid hitting the query
        # complexity limit.
        navigatorItemsCollection(limit: 15) {
          items {
            ...NavigatorItemFieldsOnly
            navigatorItemsCollection(limit: 15) {
              items {
                # Note: this is 3x nested to support the 3x nested nav for forbusiness.snapchat.com
                ...NavigatorItemFieldsOnly
              }
              total
            }
          }
          total
        }
      }
      total
    }
    callsToActionCollection(limit: 10) {
      items {
        ... on CallToAction {
          ...CallToActionAll
        }
        ... on NavigationCallToAction {
          ...NavigationCta
        }
      }
    }
    url
    analytics {
      ...AnalyticsAll
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
  ${navigatorItemFragment}
  ${ctaFragment}
  ${navigationCtaFragment}
  ${mediaFragments.all}
`;

export interface NavigatorItemWithChildrenData extends NavigatorItemData {
  navigatorItemsCollection: Items<NavigatorItemWithChildrenData>;
}

interface NavigatorData extends ContentfulTypedSysProps<'navigator'> {
  title: string;
  logoV2: ImageDataProps;
  theme?: BackgroundColor;
  backgroundColor?: BackgroundColor;
  callsToActionCollection: Items<NavigatorCallsToActionItem>;
  navigatorItemsCollection: Items<NavigatorItemWithChildrenData>;
  url?: string;
  analytics?: AnalyticsDataProps;
}

// =================================================================================================
// Navigator Query
// =================================================================================================

export const navigatorCollectionQuery = gql`
  query NavigatorCollectionQuery($preview: Boolean!, $locale: String!) {
    navigatorCollection(preview: $preview, locale: $locale, limit: 1) {
      items {
        ...NavigatorLocal
      }
      total
    }
  }
  ${navigatorFragment}
`;

export interface NavigatorCollectionData {
  navigatorCollection: Items<NavigatorData>;
}
