import type { NavigatorItem as NavigatorItemType } from '@snapchat/mw-contentful-schema';
import {
  defaultGlobalNavMobileGroupKey,
  GlobalHeaderContext,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { PathAwareNavItem } from '../Header/headerPathUtils';
import { LocalNavItemMobile } from './LocalNavItemMobile';

interface LocalNavMobileProps {
  items?: PathAwareNavItem[];
}

/**
 * Local nav links styled to look like the global nav, but only for mobile.
 *
 * It is only on mobile that we style the local nav in the same fashion as global nav.
 */
export const LocalNavMobile: FC<LocalNavMobileProps> = ({ items }) => {
  const { setGroupKey } = useContext(GlobalHeaderContext);

  useEffect(() => {
    setGroupKey?.(defaultGlobalNavMobileGroupKey);
  }, [setGroupKey]);

  if (!items || !items.length) {
    return null;
  }

  return (
    <>
      {items.map(props => {
        const { contentfulDescriptionDataset } = getContentfulInspectorProps<NavigatorItemType>({
          entryId: props.id,
          fieldIds: ['contentfulDescription'],
        });

        return (
          <LocalNavItemMobile key={props.id} {...props} dataset={contentfulDescriptionDataset} />
        );
      })}
    </>
  );
};

LocalNavMobile.displayName = 'LocalNavMobile';
