import { Config } from '../config';
import { UrlParameter } from '../constants/urlParameters';
import { isLocalUrl } from './getLocalPath';

/** Parameters we should always persist when navigating within the same tab */
export const persistedQueryParams = [
  ...(Config.persistentQueryParams ?? []),
  UrlParameter.GOOGLE_TAG_MANAGER_DEBUG,
  UrlParameter.USE_ALTERNATE_MOTIF,
];

/** Parameters we should always persist, even for external navigation */
export const alwaysPersistQueryParams = ['lang'];

export const checkIfSameTabDomain = (targetUrl: URL, currentUrl: URL): boolean => {
  // used for routing path redirects
  if (isLocalUrl(targetUrl)) return true;

  const sameDomains = [...(Config.sameTabDomains ?? []), currentUrl.hostname];
  return sameDomains.some((domain: string) => targetUrl.hostname === domain);
};

export const checkIfPersistParamsDomain = (url: URL, currentUrl: URL): boolean => {
  // used for routing path redirects
  if (isLocalUrl(url)) return true;

  const persistDomains = new Set(Config.persistQueryParamsDomains ?? []);
  persistDomains.add(currentUrl.hostname);
  return persistDomains.has(url.hostname);
};
