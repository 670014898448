import { gql } from '@apollo/client';
import type { ButtonSize, ButtonType, IconName } from '@snapchat/snap-design-system-marketing';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as imageFragments } from '../Image/query';
import type { MediaDataProps } from '../Media';
import { fragments as mediaFragments } from '../Media';

// =================================================================================================
// Button Fragment
// =================================================================================================

export const buttonFragment = gql`
  fragment ButtonAll on Button {
    ...ContentfulSysId
    title {
      json
    }
    url
    targetAsset {
      ...AssetAll
    }
    size
    theme
    buttonType
    image {
      ... on Image {
        ...ImageAll
      }
    }
    iconName
  }
  ${mediaFragments.all}
  ${imageFragments.all}
  ${contentfulSysIdFragment}
`;

export interface ButtonData extends ContentfulTypedSysProps<'Button'> {
  url?: string;
  targetAsset?: MediaDataProps;
  theme?: ButtonType;
  buttonType?: ButtonType;
  size: ButtonSize;
  title: RichText;
  // TODO: Update type to match ImageData.
  image?: {
    media: MediaDataProps & {
      width: number;
      height: number;
    };
  };
  iconName?: IconName;
}

// NOTE: No query to load a button separately because when we had it we ended up in a button-loading
// hell with dozen of separate queries to load individual buttons.
