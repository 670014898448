import { css } from '@emotion/css';

import { Spacing } from '../../../constants';
import {
  opacityFadeInCss,
  opacityFadeOutCss,
  slideDownCss,
  slideUpCss,
} from '../../../constants/animation';
import { ZIndex } from '../../../constants/zIndex';
import { ToggleState } from '../../../hooks/useToggleState';
import { m } from '../../../motif/m';
import { scrollbarCss } from '../../../utils/scrollbarCss';
import {
  globalHeaderAnimationDuration,
  globalHeaderHeight,
  globalLinksClassName,
  globalNavLevelClassName,
  globalNavMobileLocalNavClassName,
  globalNavOuterMargin,
  mobileHeaderHeight,
  navHeaderShadowSize,
  nextLevelLineHeightCssVar,
} from '../GlobalHeader.constants';

// NOTE: This component is NOT themable. We use constants here directly without going through the
// theme.

export const screenStateClassName = (state: ToggleState): string => `screen-state-${state}`;

const globalNavCss = css`
  background-color: ${m('--global-header-nav-screen-bg-color')};
  color: ${m('--global-header-nav-screen-fg-color')};

  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${ZIndex.NAVIGATOR};

  & header {
    height: ${globalHeaderHeight}px;
    padding: 0 60px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(
      0deg,
      transparent 0,
      ${m('--global-header-nav-screen-bg-color')} ${navHeaderShadowSize}px
    );
    margin-bottom: -${navHeaderShadowSize}px;
    z-index: ${ZIndex.NAVIGATOR_HEADER};

    &.sticky {
      position: sticky;
      top: 0;
    }
  }
`;

/**
 * Styles for the items and headings in global nav.
 *
 * I (Alex) had a hard time keeping CSS straight between 3 different levels where some are links and
 * others aren't, and local nav on mobile being a headless list of nav items without a container
 * group. And with mocks changing frequently it made it hard to prevent styles bleeding without
 * figuring out what level they are at. So this styles is structured to work with the level tracking
 * class attribution.
 */

const levelSelector = (level: 1 | 2 | 3): string => `.${globalNavLevelClassName} `.repeat(level);
export const globalNavDesktopFontCss = css`
  & a {
    color: ${m('--global-header-item-color')};
    text-decoration: none;
    position: relative;

    &::after {
      left: 0;
      content: ' ';
      position: absolute;
      bottom: 5px;
      height: 0;
      width: 100%;
      border-bottom: 2px ${m('--global-header-item-hover-color')} solid;

      transition: ease-in-out;
      transition-duration: 100ms;
      opacity: 0;
    }
  }

  & a:hover,
  & a:focus {
    color: ${m('--global-header-item-hover-color')};

    &::after {
      opacity: 1;
    }
  }

  & ${levelSelector(1)} {
    font-size: 26px;
    line-height: 54px;
    font-weight: 600;
    ${nextLevelLineHeightCssVar}: 36px;
  }

  & ${levelSelector(2)} {
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    ${nextLevelLineHeightCssVar}: 16px;
  }

  & ${levelSelector(3)} {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
  }
`;

export const globalNavMobileFontCss = css`
  & a {
    color: ${m('--global-header-item-color')};
    text-decoration: none;
  }
  & a:hover {
    color: ${m('--global-header-item-hover-color')};
  }

  .${globalNavLevelClassName} {
    font-size: ${m('--h6-desktop-font-size')};
    font-weight: 500;
    line-height: 40px;
  }

  & ${levelSelector(2)}, & ${levelSelector(3)} {
    font-weight: 400;
  }
`;

/** Style for the desktop full screen navigation. */
const leftNavWidth = 400;
export const globalNavHighlightClassName = 'highlight';
export const globalNavNoScrollClassName = 'no-scroll';
export const globalNavLeftNavClassName = 'left-nav';
export const globalNavDesktopCss = css`
  ${globalNavCss}

  ${slideDownCss({ duration: `${globalHeaderAnimationDuration}ms` })}
  &.${screenStateClassName(ToggleState.ON)},
  &.${screenStateClassName(ToggleState.TURNING_ON)} {
    ${slideUpCss({ duration: `${globalHeaderAnimationDuration}ms` })}
  }

  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;

  box-sizing: border-box;
  overflow: hidden;

  & > .${globalNavLeftNavClassName} {
    height: 100%;
    width: ${leftNavWidth}px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    ${globalNavDesktopFontCss}

    /* Styling scrollbar */
    ${scrollbarCss({
      thumbColor: m('--global-header-item-color'),
      backgroundColor: m('--global-header-nav-screen-bg-color'),
      trackWidth: '10px',
    })}
  }

  & > .${globalNavLeftNavClassName}.${globalNavNoScrollClassName} {
    ${scrollbarCss({
      thumbColor: m('--global-header-nav-screen-bg-color'),
      backgroundColor: m('--global-header-nav-screen-bg-color'),
      trackWidth: '10px',
    })}
  }

  & .menu {
    padding: 0px 20px 0px ${globalNavOuterMargin};
    box-sizing: border-box;
  }

  *[dir='rtl'] & .menu {
    padding: 0px ${globalNavOuterMargin} 0px 20px;
  }

  & > .${globalNavHighlightClassName} {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(100% - ${leftNavWidth}px);
    z-index: ${ZIndex.NAVIGATOR_HIGHLIGHT};

    *[dir='rtl'] & {
      right: unset;
      left: 0;
    }
  }

  & .close {
    position: relative;
  }
`;

/** Style for the mobile full screen header. */
export const globalNavMobileCss = css`
  ${globalNavCss}
  ${globalNavMobileFontCss}

  ${opacityFadeInCss({ duration: `${globalHeaderAnimationDuration}ms`, curve: 'ease-out' })}

  &.${screenStateClassName(ToggleState.TURNING_OFF)} {
    ${opacityFadeOutCss({ duration: `${globalHeaderAnimationDuration}ms`, curve: 'ease-out' })}
  }

  overflow: hidden scroll;
  display: flex;
  flex-direction: column;

  & > .${globalNavMobileLocalNavClassName} {
    padding: 0 20px 20px 20px;
    scroll-margin-top: 5px;
  }

  & > .${globalLinksClassName} {
    background-color: ${m('--global-header-nav-screen-bg-color')};
    color: ${m('--global-header-nav-screen-fg-color')};
    padding: 20px 20px 40px 20px;
    margin-bottom: -20px; /* Pushing background under the gradient in .highlight. */
    flex-grow: 1; /* Pushes the gray background to the bottom for long pages. */
  }

  & hr {
    border-style: inset;
    border-width: 1px;
    margin-block-start: ${Spacing.MD}px;
    margin-block-end: ${Spacing.MD}px;
  }

  /* On mobile we show the header regardless, so we display the nav below it. */
  /* NOTE: Reducing so that at 75% zoom a sliver of background doesn't show. */
  top: ${mobileHeaderHeight - 0.75}px;
  /* On mobile we need to subtract the header size. */
  max-height: calc(100vh - ${mobileHeaderHeight}px);
  height: calc(100vh - ${mobileHeaderHeight}px);
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  max-height: calc(100dvh - ${mobileHeaderHeight}px);
  height: calc(100dvh - ${mobileHeaderHeight}px);

  width: 100vw;
  max-width: 100vw;
`;

export const globalNavMobileHeadingCss = css`
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  display: block;
`;
