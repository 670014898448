import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { AppContext } from '../../AppContext';
import { defaultLocale } from '../../helpers/locale';

interface CanonicalProps {
  /**
   * Array of locales that a page is localized in. Defaults to en-US in case the field is undefined
   * (this happens if the Page has no titles set yet)
   */
  localizedIn?: string[];
}

/** Adds canonical based on whether or not the page is localized in the current locale. */
export const Canonical: FC<CanonicalProps> = ({ localizedIn = [defaultLocale] }) => {
  const { currentLocale, getCurrentUrl } = useContext(AppContext);

  const url = new URL(getCurrentUrl());

  // TODO: filter query params to persist only the relevant ones: https://jira.sc-corp.net/browse/WEBP-10759
  const currentUrlHasLang = url.searchParams.has('lang');
  url.search = '';
  url.hash = '';

  // If the current URL does not have a lang param do not add one
  if (currentUrlHasLang) {
    // if localized in the current local, use the current locale lang param as canonical
    if (localizedIn.includes(currentLocale as string)) {
      url.searchParams.set('lang', currentLocale as string);
    } else {
      // if not localized in the current locale, use the default locale lang param as canonical
      url.searchParams.set('lang', defaultLocale);
    }
  }

  return (
    <Helmet>
      <link rel="canonical" href={url.href} />
    </Helmet>
  );
};
