import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../Analytics/query';

export const formRowFragment = gql`
  fragment FormRowAll on FormRow {
    ...ContentfulSysId
    rowAsText {
      json
    }
    fieldsCollection(limit: 3) {
      items {
        __typename
        ... on InputField {
          ...ContentfulSysId
          name
          type
          label
          richLabel {
            json
          }
          initialValue
          shouldResetToInitial
          placeholder
          required
          validation
          helpText
          error {
            json
          }
          maxLength
          minValue
          maxValue
        }
        ... on SubmitButton {
          ...ContentfulSysId
          name
        }
        ... on PresetDropdownField {
          ...ContentfulSysId
          name
          label
          preset
          placeholder
          required
          helpText
          error {
            json
          }
        }
        ... on DropdownField {
          ...ContentfulSysId
          name
          label
          placeholder
          required
          helpText
          optionsCollection {
            items {
              ... on DropdownFieldOption {
                ...ContentfulSysId
                key
                value
              }
            }
          }
          initialOption {
            ... on DropdownFieldOption {
              ...ContentfulSysId
              key
              value
            }
          }
          shouldResetToInitial
          error {
            json
          }
        }
        ... on MultiselectDropdownField {
          ...ContentfulSysId
          name
          label
          placeholder
          required
          helpText
          optionsCollection {
            items {
              ... on DropdownFieldOption {
                ...ContentfulSysId
                key
                value
              }
            }
          }
          error {
            json
          }
        }
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export const extraParametersFormFeatureFragment = gql`
  fragment ExtraParametersFormFeatureAll on ExtraParametersFormFeature {
    ...ContentfulSysId
    utmCampaignFieldName
    utmContentFieldName
    utmMediumFieldName
    utmSourceFieldName
    utmTermFieldName
  }
  ${contentfulSysIdFragment}
`;

export const formFragments = {
  all: gql`
    fragment FormAll on Form {
      ...ContentfulSysId
      endpoint
      redirectUrl
      responseType
      redirectTimeout
      submitText
      submitSuccessText
      analytics {
        ...AnalyticsAll
      }
      persistFieldEdits
      prepopulatePerQueryParams
      # limit raised to 20 for avalon form use case
      rowsCollection(limit: 20) {
        items {
          ...FormRowAll
        }
      }
      # current expected case is a 0-1 features, but this way we can accommodate future cases w/ minimal effort
      additionalFeaturesCollection(limit: 5) {
        items {
          ... on ExtraParametersFormFeature {
            ...ExtraParametersFormFeatureAll
          }
        }
      }
    }
    ${formRowFragment}
    ${extraParametersFormFeatureFragment}
    ${contentfulSysIdFragment}
    ${analyticsFragments.all}
  `,
};

export const queries = {
  shallow: gql`
    query FormQuery($preview: Boolean!, $locale: String!, $id: String!) {
      form(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query FormQuery($preview: Boolean!, $locale: String!, $id: String!) {
      form(preview: $preview, locale: $locale, id: $id) {
        ...FormAll
      }
    }
    ${formFragments.all}
  `,
};
