import type { ReactNode } from 'react';

import type { BaseComponentProps, BaseInteractiveComponentProps } from '../../../types';

export interface GlobalNavItemProps
  extends BaseInteractiveComponentProps<HTMLElement>,
    BaseComponentProps {
  title: string; // TODO: Consider making this a react node.
  href?: string;
  target?: string;
  showExternalIcon?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
  addTrackingParams?: boolean;
}
