import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { NavigatorItem as NavigatorItemType } from '@snapchat/mw-contentful-schema';
import { GlobalNavItem as GlobalNavItemSDS } from '@snapchat/snap-design-system-marketing';
import { type FC, type MouseEventHandler, useContext } from 'react';

import { AppContext } from '../../AppContext';
import { isLocalUrl, shouldFlushLogs } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { PathAwareNavItem } from '../Header/headerPathUtils';
import { useConsumerContext } from '../useConsumerContext';

export const LocalNavItemMobile: FC<PathAwareNavItem> = props => {
  const { logEvent } = useConsumerContext();
  const { getCurrentUrl } = useContext(AppContext);

  if (!props) {
    return null;
  }

  const { analyticsId, url, title, isSelected, subItems, dataset } = props;

  if (!title) {
    // Do nothing if there's no title.
    return null;
  }

  const onClick: MouseEventHandler = (): void => {
    logEvent?.({
      type: UserAction.Click,
      component: 'LocalNavItemMobile',
      label: analyticsId,
      url,
    });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  // this should return true for relative paths too. Prevent adding tracking para
  const absoluteUrl = new URL(url ?? '', getCurrentUrl());
  const isLocal = isLocalUrl(absoluteUrl);

  return (
    <GlobalNavItemSDS
      showExternalIcon={false}
      href={url}
      title={documentToPlainTextString(title.json)}
      onClick={onClick}
      isSelected={isSelected}
      dataset={dataset}
      addTrackingParams={!isLocal}
    >
      {subItems.map(props => {
        const { contentfulDescriptionDataset } = getContentfulInspectorProps<NavigatorItemType>({
          entryId: props.id,
          fieldIds: ['contentfulDescription'],
        });

        return (
          <LocalNavItemMobile key={props.id} {...props} dataset={contentfulDescriptionDataset} />
        );
      })}
    </GlobalNavItemSDS>
  );
};

LocalNavItemMobile.displayName = 'LocalNavItemMobile';
